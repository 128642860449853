
import { defineComponent, onMounted, ref, nextTick } from "vue";

import banner from "@/components/common/Swiper.vue";
import fixedHeader from "@/components/common/Header.vue";
//  utils
import api from "@/api/axios";
import Swiper from "swiper";

export default defineComponent({
  name: "Index",
  components: {
    banner,
    fixedHeader,
  },

  setup() {
    onMounted(() => {
      // // eslint-disable-next-line
      // (function () {
      //    // eslint-disable-next-line
      //   var _hmt: any = _hmt || [];
      //   (function () {
      //     const hm = document.createElement("script");
      //     hm.src =
      //       "https://hm.baidu.com/hm.js?b35a4e83a595b99db52701f8ac55af3d";
      //     const s = document.getElementsByTagName("script")[0];
      //     s.parentNode?.insertBefore(hm, s);
      //   })();
      // })();
      nextTick(() => {
        const swiper = new Swiper("#hot-swiper", {
          slidesPerView: 3,
          initialSlide: 0,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },
          },
        });
      });
    });

    const list = ref(null);
    const hotSaleList = ref(null);
    api.get("/M").then((res): void => {
      list.value = res.data.obj.listimg;
      hotSaleList.value = res.data.obj.hotSaleList;
    });
    //  切换列表菜单
    type Menu = "新闻" | "帖子" | "直播" | "热销";
    const menu: Menu[] = ["直播", "新闻", "帖子", "热销"];
    const currentMenuItem = ref(menu[0]);
    function selectMenu(item: Menu) {
      currentMenuItem.value = item;
    }

    return {
      list,
      hotSaleList,
      menu,
      selectMenu,
      currentMenuItem,
    };
  },
});
